import CMS from "@staticcms/app"

CMS.registerEventListener({
  name: "preSave",
  handler: ({ entry, CMS }) => {
    // Your custom logic here before saving the entry
    if (entry.get("newRecord")) {
      // Get the current date and time
      const currentDate = new Date()

      // Set the creation date field in the entry's data
      entry.set("data.date", currentDate)
    }
  },
})
